import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import {
    IOptionProps,
    SliderWithField,
    Select,
    DisguisedRadioButton,
    Grid,
    Paragraph,
    IntegerInput,
    Heading,
    NonBreakingSpace,
    Checkbox,
    Label,
    TransBold,
    Fieldset,
} from "@econans/ui-components";

import type { IFormValues } from "@Components/shared.types";
import { CustomerScenario } from "@Components/shared.types";
import { formatLocalPercent, toDecimal, toPercent, TranslationNamespaceEnum, useConfiguration } from "@Utils";
import { FormGroup, LabelWithCheckbox, styles } from "./Form.styles";

interface IProps {
    formValues: IFormValues;
    interestOptions: Array<IOptionProps>;
    handleChange(property: keyof IFormValues, newValue: number | string | boolean | null): void;
}

export const Form: React.FC<IProps> = ({ formValues, interestOptions, handleChange }) => {
    const { t } = useTranslation([TranslationNamespaceEnum.FORM, TranslationNamespaceEnum.CURRENCY]);
    const theme = useTheme();
    const configuration = useConfiguration();
    const { maxLoanToValueRatio, membershipDiscount, hideCustomerScenario, clearOnFocusIfZero } = useConfiguration();
    const [maxLoanAmount, setMaxLoanAmount] = useState(Math.floor(formValues.housingValue * maxLoanToValueRatio));
    const maxLTVRatioPresentationalValue = formatLocalPercent(maxLoanToValueRatio, 0);
    const maxDownPaymentRatioPresentationalValue = formatLocalPercent(1 - maxLoanToValueRatio, 0);

    const membershipIsDisabled = formValues.listInterestId === "custom";

    useEffect(() => {
        setMaxLoanAmount(Math.floor(formValues.housingValue * maxLoanToValueRatio));
    }, [formValues.housingValue, maxLoanToValueRatio]);

    const customerScenarioControls: Array<React.ReactNode> = useMemo(
        () =>
            Object.values(CustomerScenario).map((scenario) => (
                <DisguisedRadioButton
                    key={scenario}
                    radioButtonProps={{
                        name: "customer-scenario",
                        value: scenario,
                        onChange: (event) => handleChange("customerScenario", event.target.value),
                        checked: formValues.customerScenario === scenario,
                    }}
                    content={t(`customer-scenario-option-${scenario}`)}
                />
            )),
        [formValues.customerScenario, t, handleChange],
    );

    return (
        <React.Fragment>
            <Heading level={theme.widget?.Form?.headingLevel || "3"}>{t("heading")}</Heading>

            <form onSubmit={(event) => event.preventDefault()} css={styles.form}>
                {!hideCustomerScenario && (
                    <Fieldset
                        variant={theme.widget?.Form?.customerScenarioControls?.fieldsetVariant}
                        legend={t("customer-scenario-label")}
                        legendVariant={theme.widget?.Form?.customerScenarioControls?.legendVariant}
                    >
                        <Grid columns={{ mobile: 1, desktop: 2 }} gap={theme.widget?.Form?.customerScenarioControls?.gap ?? theme.spacing(4)}>
                            {customerScenarioControls}
                        </Grid>
                    </Fieldset>
                )}

                <FormGroup>
                    <Label htmlFor="housing-value">
                        {t(formValues.customerScenario === CustomerScenario.MOVE_EXISTING_MORTGAGE ? "housing-value-label" : "housing-price-label")}
                    </Label>
                    {configuration.showInputSliders ? (
                        <SliderWithField
                            fieldType="integer"
                            value={formValues.housingValue}
                            onChange={(newValue) => handleChange("housingValue", newValue)}
                            min={100000}
                            max={20000000}
                            allowHigherThanMax
                            step={100000}
                            suffix={t(`${TranslationNamespaceEnum.CURRENCY}:sek`)}
                            fieldHtmlAttributes={{ id: "housing-value" }}
                        />
                    ) : (
                        <IntegerInput
                            id="housing-value"
                            value={formValues.housingValue}
                            onChange={(newValue) => handleChange("housingValue", newValue)}
                            min={100000}
                            max={20000000}
                            allowHigherThanMax
                            step={100000}
                            suffix={t(`${TranslationNamespaceEnum.CURRENCY}:sek`)}
                            clearOnFocusIfZero={clearOnFocusIfZero}
                        />
                    )}
                </FormGroup>

                {formValues.customerScenario === CustomerScenario.MOVE_EXISTING_MORTGAGE && (
                    <FormGroup>
                        <Label htmlFor="mortgage-amount" data-testid="mortgage-amount-label">
                            {t("mortgage-amount-label")}
                        </Label>

                        {configuration.showInputSliders ? (
                            <SliderWithField
                                fieldType="integer"
                                value={formValues.mortgageAmount}
                                onChange={(newValue) => handleChange("mortgageAmount", newValue)}
                                min={0}
                                max={maxLoanAmount}
                                step={50000}
                                suffix={t(`${TranslationNamespaceEnum.CURRENCY}:sek`)}
                                fieldHtmlAttributes={{ id: "mortgage-amount" }}
                            />
                        ) : (
                            <IntegerInput
                                id="mortgage-amount"
                                value={formValues.mortgageAmount}
                                onChange={(newValue) => handleChange("mortgageAmount", newValue)}
                                min={0}
                                max={maxLoanAmount}
                                step={50000}
                                suffix={t(`${TranslationNamespaceEnum.CURRENCY}:sek`)}
                                clearOnFocusIfZero={clearOnFocusIfZero}
                            />
                        )}

                        {configuration.showFormDisclaimers?.mortgageAmount && (
                            <Paragraph noBottomMargin css={[styles.inputFieldDisclaimer, styles.mortgageAmountDisclaimer]}>
                                <Trans
                                    t={t}
                                    i18nKey="mortgage-amount-disclaimer"
                                    tOptions={{
                                        interpolation: { escapeValue: false },
                                    }}
                                    components={{ nobr: <NonBreakingSpace /> }}
                                    values={{ maxLTVRatioPresentationalValue }}
                                />
                            </Paragraph>
                        )}
                    </FormGroup>
                )}

                {formValues.customerScenario === CustomerScenario.BUY_NEW_HOUSING && (
                    <FormGroup>
                        <Label htmlFor="down-payment" data-testid="down-payment-label">
                            {t("down-payment-label")}
                        </Label>

                        {configuration.showInputSliders?.downPayment ? (
                            <SliderWithField
                                fieldType="integer"
                                value={formValues.downPayment}
                                onChange={(newValue) => handleChange("downPayment", newValue)}
                                min={formValues.housingValue - maxLoanAmount}
                                max={formValues.housingValue}
                                step={50000}
                                suffix={t(`${TranslationNamespaceEnum.CURRENCY}:sek`)}
                                fieldHtmlAttributes={{ id: "down-payment" }}
                            />
                        ) : (
                            <IntegerInput
                                id="down-payment"
                                value={formValues.downPayment}
                                onChange={(newValue) => handleChange("downPayment", newValue)}
                                min={formValues.housingValue - maxLoanAmount}
                                max={formValues.housingValue}
                                step={50000}
                                suffix={t(`${TranslationNamespaceEnum.CURRENCY}:sek`)}
                                clearOnFocusIfZero={clearOnFocusIfZero}
                            />
                        )}

                        {configuration.showFormDisclaimers?.downPaymentAmount && (
                            <Paragraph noBottomMargin css={[styles.inputFieldDisclaimer, styles.downPaymentDisclaimer]}>
                                <Trans
                                    t={t}
                                    i18nKey="down-payment-disclaimer"
                                    tOptions={{
                                        interpolation: { escapeValue: false },
                                    }}
                                    components={{ nobr: <NonBreakingSpace /> }}
                                    values={{ maxDownPaymentRatioPresentationalValue }}
                                />
                            </Paragraph>
                        )}
                    </FormGroup>
                )}

                <FormGroup>
                    <Label htmlFor="list-interest">{t("list-interest-label")}</Label>
                    {configuration.showFormDisclaimers?.listInterest && (
                        <Paragraph css={[styles.inputFieldDisclaimer, styles.listInterestDisclaimer]}>
                            <Trans
                                t={t}
                                i18nKey={`${TranslationNamespaceEnum.FORM}:list-interest-disclaimer`}
                                tOptions={{
                                    interpolation: { escapeValue: false },
                                }}
                                components={{
                                    bold: <TransBold />,
                                }}
                            />
                        </Paragraph>
                    )}
                    <Select
                        value={formValues.listInterestId}
                        onChange={(event) => handleChange("listInterestId", event.target.value)}
                        options={interestOptions}
                        id="list-interest"
                    />
                    <SliderWithField
                        fieldType="decimal"
                        value={toPercent(formValues.interestRate)}
                        onChange={(newValue) => handleChange("interestRate", toDecimal(newValue))}
                        min={0}
                        max={10}
                        step={0.05}
                        allowHigherThanMax
                        suffix="%"
                        fieldHtmlAttributes={{
                            "aria-labelledby": "list-interest",
                            maxLength: 6,
                        }}
                        sliderHtmlAttributes={{
                            "aria-labelledby": "list-interest",
                        }}
                        css={styles.formInterestSliderWithField}
                    />
                </FormGroup>
            </form>

            {membershipDiscount?.partner && typeof membershipDiscount.discount === "number" && (
                <FormGroup>
                    <Heading css={styles.formMembershipDiscountHeading} level={theme.widget?.Form?.membershipDiscount?.heading?.level || "6"}>
                        {t("form-membership-label", { partner: membershipDiscount.partner })}
                    </Heading>
                    <Paragraph css={styles.formMembershipDiscountDescription}>{t("form-membership-description")}</Paragraph>
                    <LabelWithCheckbox isDisabled={membershipIsDisabled}>
                        <Checkbox
                            checked={formValues.membership}
                            onChange={() => handleChange("membership", !formValues.membership)}
                            disabled={membershipIsDisabled}
                        />
                        {t("form-membership-toggle", { partner: membershipDiscount.partner })}
                    </LabelWithCheckbox>
                </FormGroup>
            )}

            {configuration.showFormDisclaimers?.formEnd && (
                <React.Fragment>
                    <Heading level={theme.widget?.Form?.formEndDisclaimer?.label?.level || "4"} css={styles.formEndLabel}>
                        {t("form-end-label")}
                    </Heading>
                    <Paragraph css={styles.formEndDisclaimer}>{t("form-end-disclaimer")}</Paragraph>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

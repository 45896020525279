import { css } from "@emotion/react";
import type { Theme } from "@emotion/react";
import type { ICSSProperties, IHeadingProps } from "@econans/ui-components";

const comparison = () => css`
    all: revert;
    box-sizing: border-box;
    display: block;
    margin: 0;
    padding: 0;
`;

const comparisonForm = (theme: Theme) =>
    css({
        all: "revert",
        boxSizing: "border-box",
        display: "block",
        margin: theme.spacing(0, 0, 6),
        padding: 0,
        ...theme.widget?.Comparison?.form,
    });

const comparisonResultValue = (theme: Theme) =>
    css({
        all: "revert",
        display: "block",
        margin: theme.spacing(0, 0, 4),
        padding: 0,
        ...theme.widget?.Comparison?.result?.value,
        fontFamily: theme.typography.font[theme.widget?.Comparison?.result?.value?.fontFamily || "primary"],
        fontSize: theme.typography.size[theme.widget?.Comparison?.result?.value?.fontSize || "lg"],
    });

const comparisonResultNegativeDifferenceDisclaimer = (theme: Theme) =>
    css({
        ...theme.widget?.Comparison?.result?.negativeDifferenceDisclaimer,
        fontFamily: theme.widget?.Comparison?.result?.negativeDifferenceDisclaimer?.fontFamily
            ? theme.typography.font[theme.widget.Comparison.result.negativeDifferenceDisclaimer.fontFamily]
            : undefined,
        fontSize: theme.widget?.Comparison?.result?.negativeDifferenceDisclaimer?.fontSize
            ? theme.typography.size[theme.widget.Comparison.result.negativeDifferenceDisclaimer.fontSize]
            : undefined,
    });

const comparisonDisclaimer = (theme: Theme) =>
    css({
        ...theme.widget?.Comparison?.disclaimer,
        fontFamily: theme.widget?.Comparison?.disclaimer?.fontFamily
            ? theme.typography.font[theme.widget.Comparison.disclaimer.fontFamily]
            : undefined,
        fontSize: theme.widget?.Comparison?.disclaimer?.fontSize ? theme.typography.size[theme.widget.Comparison.disclaimer.fontSize] : undefined,
    });

export const styles = { comparison, comparisonForm, comparisonResultValue, comparisonResultNegativeDifferenceDisclaimer, comparisonDisclaimer };

export interface IComparisonStyleOverrides {
    headingLevel?: IHeadingProps["level"];
    form?: Pick<ICSSProperties, "margin" | "padding" | "background" | "border" | "borderBottom" | "boxShadow">;
    result?: {
        headingLevel?: IHeadingProps["level"];
        value?: Pick<ICSSProperties, "margin" | "padding" | "fontFamily" | "fontWeight" | "fontSize" | "lineHeight" | "color">;
        negativeDifferenceDisclaimer?: Pick<ICSSProperties, "margin" | "fontFamily" | "fontWeight" | "fontSize" | "lineHeight" | "color">;
    };
    disclaimer?: Pick<ICSSProperties, "margin" | "fontFamily" | "fontWeight" | "fontSize" | "lineHeight" | "color">;
}

import type { IClientConfiguration, IEvent } from "@Clients/client.types";

export const useEvent = (configuration: IClientConfiguration) => {
    const trackEvent = (event: IEvent) => {
        if (typeof configuration.onEvent === "function") {
            configuration.onEvent(event);
        }
    };

    return { trackEvent };
};

import type { IListInterestData } from "@econans/calculations";
import type { IClientConfiguration, IClientConfigurationOverrides } from "@Clients/client.types";
import { CustomerScenario } from "@Components/shared.types";

export function getConfiguration(overrides?: IClientConfigurationOverrides): IClientConfiguration {
    const DEFAULT_CUSTOMER_SCENARIO = overrides?.defaults?.customerScenario || CustomerScenario.MOVE_EXISTING_MORTGAGE;
    const DEFAULT_HOUSING_VALUE = overrides?.defaults?.housingValue ?? 5000000;
    const DEFAULT_MORTGAGE_AMOUNT = overrides?.defaults?.mortgageAmount ?? 3400000;
    const DEFAULT_USER_INTEREST = 0.04;
    const MAX_LOAN_TO_VALUE_RATIO = overrides?.maxLoanToValueRatio ?? 0.85;
    const MIN_MORTGAGE_LTV_DISCOUNT_LIMIT = overrides?.minMortgageLtvDiscountLimit ?? 0;
    const INTEREST_RATES: Array<IListInterestData> = overrides?.interestRates ?? [
        {
            id: "3m",
            label: "3 mån - 1,63 %",
            listInterest: 0.0163,
            discounts: [
                { maxLTV: 0.6, discount: 0.0048 },
                { maxLTV: 0.7, discount: 0.0038 },
            ],
            period: "3 månader",
        },
        {
            id: "1y",
            label: "1 år - 1,54 %",
            listInterest: 0.0154,
            discounts: [
                { maxLTV: 0.6, discount: 0.005 },
                { maxLTV: 0.7, discount: 0.004 },
            ],
            period: "1 år",
        },
        {
            id: "2y",
            label: "2 år - 1,54 %",
            listInterest: 0.0154,
            discounts: [
                { maxLTV: 0.6, discount: 0.005 },
                { maxLTV: 0.7, discount: 0.004 },
            ],
            period: "2 år",
        },
        {
            id: "3y",
            label: "3 år - 1,57 %",
            listInterest: 0.0157,
            discounts: [
                { maxLTV: 0.6, discount: 0.0047 },
                { maxLTV: 0.7, discount: 0.0037 },
            ],
            period: "3 år",
        },
        {
            id: "4y",
            label: "4 år - 1,62 %",
            listInterest: 0.0162,
            discounts: [
                { maxLTV: 0.6, discount: 0.0047 },
                { maxLTV: 0.7, discount: 0.0037 },
            ],
            period: "4 år",
        },
        {
            id: "5y",
            label: "5 år - 1,67 %",
            listInterest: 0.0167,
            discounts: [
                { maxLTV: 0.6, discount: 0.0047 },
                { maxLTV: 0.7, discount: 0.0037 },
            ],
            period: "5 år",
        },
    ];

    const defaultListInterest = INTEREST_RATES.find(({ id }) => id === overrides?.defaults?.listInterestId) ?? INTEREST_RATES[0];

    return {
        defaults: {
            customerScenario: DEFAULT_CUSTOMER_SCENARIO,
            interestRate: defaultListInterest.listInterest,
            listInterestId: defaultListInterest.id,
            housingValue: DEFAULT_HOUSING_VALUE,
            mortgageAmount: DEFAULT_MORTGAGE_AMOUNT,
            userInterestRate: DEFAULT_USER_INTEREST,
        },
        maxLoanToValueRatio: MAX_LOAN_TO_VALUE_RATIO,
        minMortgageLtvDiscountLimit: MIN_MORTGAGE_LTV_DISCOUNT_LIMIT,
        interestRates: INTEREST_RATES,
        hideCustomerScenario: overrides?.hideCustomerScenario,
        showInterestComparison: overrides?.showInterestComparison ?? true,
        hideLtvDiscountInResultWhenZero: overrides?.hideLtvDiscountInResultWhenZero,
        membershipDiscount: overrides?.membershipDiscount ?? {
            partner: "Test",
            discount: 0.004,
        },
        showFormDisclaimers: {
            downPaymentAmount: true,
            formEnd: true,
            listInterest: true,
            mortgageAmount: true,
            ...overrides?.showFormDisclaimers,
        },
        showInputSliders: overrides?.showInputSliders,
        showNominalInterestInFormField: overrides?.showNominalInterestInFormField,
        ...(overrides?.onCTAClick
            ? { onCTAClick: overrides.onCTAClick }
            : {
                  onCTAClick() {
                      // eslint-disable-next-line no-console
                      console.log("Hello Cypress! The CTA button was clicked.");
                  },
              }),
        ...(overrides?.onEvent ? { onEvent: overrides.onEvent } : {}),
        ...(overrides?.translations ? { translations: overrides.translations } : {}),
    };
}

import { css } from "@emotion/react";
import type { Theme } from "@emotion/react";
import { IButtonProps, ICSSProperties } from "@econans/ui-components";

const result = () => css`
    all: revert;
    box-sizing: border-box;
    display: block;
    margin: 0;
    padding: 0;
`;

const totals = (theme: Theme) =>
    css({
        margin: theme.widget?.Result?.totals?.margin ?? theme.spacing(0, 0, 8),
        padding: theme.widget?.Result?.totals?.padding ?? 0,
        background: theme.widget?.Result?.totals?.background,
    });

const withDeductionHeading = (theme: Theme) =>
    css({
        gridColumn: "1/-1",
        ...theme.widget?.Result?.totals?.withDeduction?.heading,
        fontFamily: theme.widget?.Result?.totals?.withDeduction?.heading?.fontFamily
            ? theme.typography.font[theme.widget.Result.totals.withDeduction.heading.fontFamily]
            : undefined,
        fontSize: theme.widget?.Result?.totals?.withDeduction?.heading?.fontSize
            ? theme.typography.font[theme.widget.Result.totals.withDeduction.heading.fontSize]
            : undefined,
    });

const withDeductionValue = (theme: Theme) =>
    css({
        gridColumn: "1/-1",
        textAlign: "left",
        ...theme.widget?.Result?.totals?.withDeduction?.value,
        fontFamily: theme.widget?.Result?.totals?.withDeduction?.value?.fontFamily
            ? theme.typography.font[theme.widget.Result.totals.withDeduction.value.fontFamily]
            : undefined,
        fontSize: theme.widget?.Result?.totals?.withDeduction?.value?.fontSize
            ? theme.typography.size[theme.widget.Result.totals.withDeduction.value.fontSize]
            : undefined,
    });

const resultTableAccordion = (theme: Theme) => css(theme.widget?.Result?.resultTableAccordion);

const explanationAccordion = (theme: Theme) => css(theme.widget?.Result?.explanationAccordion);

export const styles = { result, totals, withDeductionHeading, withDeductionValue, resultTableAccordion, explanationAccordion };

export interface IResultStyleOverrides {
    displayTableInAccordion?: boolean;
    displayExplanationAccordion?: boolean;
    resultTableAccordion?: Pick<ICSSProperties, "margin">;
    explanationAccordion?: Pick<ICSSProperties, "margin">;
    totals?: Pick<ICSSProperties, "margin" | "padding" | "background"> & {
        withDeduction?: {
            showHeading?: boolean;
            heading?: Pick<ICSSProperties, "padding" | "fontFamily" | "fontWeight" | "fontSize" | "lineHeight" | "color">;
            value?: Pick<ICSSProperties, "padding" | "fontFamily" | "fontWeight" | "fontSize" | "lineHeight" | "color">;
        };
    };
    cta?: Pick<IButtonProps, "variant" | "iconPlacement"> & {
        icon?: React.ComponentType;
    };
}

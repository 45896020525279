import { Trans, useTranslation } from "react-i18next";
import { css, useTheme } from "@emotion/react";
import {
    Accordion,
    Button,
    DescriptionDetail,
    DescriptionList,
    DescriptionTerm,
    Heading,
    NonBreakingSpace,
    Paragraph,
    RenderComponentInstance,
} from "@econans/ui-components";

import { CustomerScenario, IResult } from "@Components/shared.types";
import { ResultTable, VisuallyHidden } from "@Components";
import { TranslationNamespaceEnum, useConfiguration, useEvent } from "@Utils";
import { styles } from "./Result.styles";

export interface IResultProps extends React.HTMLAttributes<HTMLDivElement> {
    customerScenario: CustomerScenario;
    result: IResult;
}

export const Result: React.FC<IResultProps> = ({ customerScenario, result, ...rest }) => {
    const configuration = useConfiguration();
    const { trackEvent } = useEvent(configuration);
    const { t } = useTranslation([TranslationNamespaceEnum.RESULT, TranslationNamespaceEnum.CTA]);
    const theme = useTheme();
    const { expenses } = result;

    const handleCTAClick = () => {
        trackEvent({
            category: "user",
            action: "click",
            label: "Clicked CTA button",
        });

        if (configuration.onCTAClick) {
            configuration.onCTAClick(customerScenario);
        }
    };

    return (
        <article css={styles.result} {...rest}>
            <Heading level="3">{t("heading")}</Heading>
            <DescriptionList css={styles.totals}>
                <DescriptionTerm css={styles.withDeductionHeading}>
                    {theme.widget?.Result?.totals?.withDeduction?.showHeading ? (
                        t("total-with-deduction")
                    ) : (
                        <VisuallyHidden>{t("total-with-deduction")}</VisuallyHidden>
                    )}
                </DescriptionTerm>
                <DescriptionDetail css={styles.withDeductionValue} data-testid="with-deduction-expense">
                    {expenses.withDeduction.presentationalValue}
                </DescriptionDetail>
                <DescriptionTerm>{t("total-without-deduction")}</DescriptionTerm>
                <DescriptionDetail data-testid="without-deduction-expense">{expenses.withoutDeduction.presentationalValue}</DescriptionDetail>
            </DescriptionList>

            {theme.widget?.Result?.displayTableInAccordion ? (
                <Accordion css={styles.resultTableAccordion} id="econans-result-table-accordion" label={t("calculation-explanation-heading")}>
                    <ResultTable result={result} />
                </Accordion>
            ) : (
                <ResultTable result={result} />
            )}

            {theme.widget?.Result?.displayExplanationAccordion && (
                <Accordion
                    css={styles.explanationAccordion}
                    id="econans-calculation-explanation-accordion"
                    label={t(
                        customerScenario === CustomerScenario.MOVE_EXISTING_MORTGAGE
                            ? "calculation-explanation-heading-move-mortgage"
                            : "calculation-explanation-heading-new-mortgage",
                    )}
                >
                    <Trans
                        t={t}
                        i18nKey={
                            customerScenario === CustomerScenario.MOVE_EXISTING_MORTGAGE
                                ? "calculation-explanation-move-mortgage"
                                : "calculation-explanation-new-mortgage"
                        }
                        tOptions={{
                            interpolation: { escapeValue: false },
                        }}
                        components={{
                            nobr: <NonBreakingSpace />,
                            p: <Paragraph noBottomMarginOnLast css={css`white-space: normal;`} />,
                        }}
                    />
                </Accordion>
            )}

            {configuration.onCTAClick && (
                <Button
                    onClick={handleCTAClick}
                    variant={theme.widget?.Result?.cta?.variant || "SECONDARY"}
                    icon={theme.widget?.Result?.cta?.icon && <RenderComponentInstance instance={theme.widget?.Result?.cta?.icon} />}
                    iconPlacement={theme.widget?.Result?.cta?.iconPlacement}
                >
                    {t(
                        `${TranslationNamespaceEnum.CTA}:${
                            customerScenario === CustomerScenario.MOVE_EXISTING_MORTGAGE ? "move-mortgage-label" : "new-mortgage-label"
                        }`,
                    )}
                </Button>
            )}
        </article>
    );
};

export enum LanguageKeyEnum {
    SV = "sv",
    EN = "en",
    CUSTOM = "custom",
}

export enum TranslationNamespaceEnum {
    FORM = "form",
    RESULT = "result",
    INFO = "info",
    CTA = "cta",
    COMMON = "common",
    CURRENCY = "currency",
    COMPARISON = "comparison",
}

export interface ITranslation {
    [key: string]: string;
}

export type TranslationResources = {
    [LanguageKey in LanguageKeyEnum]?: {
        [TranslationNamespaceKey in TranslationNamespaceEnum]?: ITranslation;
    };
};

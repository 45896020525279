import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import type { ICSSProperties, IFieldsetProps, IGridProps, IHeadingProps } from "@econans/ui-components";

const form = (theme: Theme) => css`
    all: revert;
    box-sizing: border-box;
    display: grid;
    grid-auto-flow: rows;
    gap: ${theme.widget?.Form?.gap ?? theme.spacing(6)};
    margin: ${theme.widget?.Form?.margin ?? theme.spacing(0, 0, 6)};
    padding: ${theme.widget?.Form?.padding ?? 0};
`;

const inputFieldDisclaimer = (theme: Theme) =>
    css({
        ...theme.widget?.Form?.inputFieldDisclaimer,
        fontFamily: theme.widget?.Form?.inputFieldDisclaimer?.fontFamily
            ? theme.typography.font[theme.widget.Form.inputFieldDisclaimer.fontFamily]
            : undefined,
        fontSize: theme.widget?.Form?.inputFieldDisclaimer?.fontSize
            ? theme.typography.size[theme.widget.Form.inputFieldDisclaimer.fontSize]
            : undefined,
    });

const mortgageAmountDisclaimer = (theme: Theme) => css(theme.widget?.Form?.mortgageAmountDisclaimer);

const downPaymentDisclaimer = (theme: Theme) => css(theme.widget?.Form?.downPaymentDisclaimer);

const listInterestDisclaimer = (theme: Theme) => css(theme.widget?.Form?.listInterestDisclaimer);

const formMembershipDiscountHeading = (theme: Theme) => {
    const props = {
        ...theme.widget?.Form?.membershipDiscount?.heading,
        fontFamily: theme.widget?.Form?.membershipDiscount?.heading?.fontFamily
            ? theme.typography.font[theme.widget.Form.membershipDiscount.heading.fontFamily]
            : undefined,
        fontSize: theme.widget?.Form?.membershipDiscount?.heading?.fontSize
            ? theme.typography.size[theme.widget.Form.membershipDiscount.heading.fontSize]
            : undefined,
    };

    if (props.level) {
        delete props.level;
    }

    return css(props);
};

const formMembershipDiscountDescription = (theme: Theme) =>
    css({
        ...theme.widget?.Form?.membershipDiscount?.description,
        fontFamily: theme.widget?.Form?.membershipDiscount?.description?.fontFamily
            ? theme.typography.font[theme.widget.Form.membershipDiscount.description.fontFamily]
            : undefined,
        fontSize: theme.widget?.Form?.membershipDiscount?.description?.fontSize
            ? theme.typography.size[theme.widget.Form.membershipDiscount.description.fontSize]
            : undefined,
    });

const formInterestSliderWithField = (theme: Theme) =>
    css({
        margin: theme.widget?.Form?.listInterestSliderWithField?.margin ?? theme.spacing(0, 0, 3, 0),
    });

const formEndLabel = (theme: Theme) =>
    css({
        margin: theme.widget?.Form?.formEndDisclaimer?.label?.margin,
    });

const formEndDisclaimer = (theme: Theme) =>
    css({
        ...theme.widget?.Form?.formEndDisclaimer?.disclaimer,
        fontFamily: theme.widget?.Form?.formEndDisclaimer?.disclaimer?.fontFamily
            ? theme.typography.font[theme.widget.Form.formEndDisclaimer.disclaimer.fontFamily]
            : undefined,
        fontSize: theme.widget?.Form?.formEndDisclaimer?.disclaimer?.fontSize
            ? theme.typography.size[theme.widget.Form.formEndDisclaimer.disclaimer.fontSize]
            : undefined,
    });

export const styles = {
    form,
    inputFieldDisclaimer,
    mortgageAmountDisclaimer,
    downPaymentDisclaimer,
    listInterestDisclaimer,
    formInterestSliderWithField,
    formMembershipDiscountHeading,
    formMembershipDiscountDescription,
    formEndLabel,
    formEndDisclaimer,
};

export const LabelWithCheckbox = styled.label<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
    all: "revert",
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gap: theme.widget?.Form?.membershipDiscount?.checkboxLabel?.gap ?? theme.spacing(2),
    alignItems: "center",
    margin: 0,
    padding: 0,
    fontFamily: theme.typography.font[theme.widget?.Form?.membershipDiscount?.checkboxLabel?.fontFamily || "primary"],
    fontSize: theme.typography.size[theme.widget?.Form?.membershipDiscount?.checkboxLabel?.fontSize || "md"],
    fontWeight: theme.widget?.Form?.membershipDiscount?.checkboxLabel?.fontWeight,
    color: theme.widget?.Form?.membershipDiscount?.checkboxLabel?.color || theme.typography.color.primary,
    cursor: theme.widget?.Form?.membershipDiscount?.checkboxLabel?.cursor || "pointer",
    ...(isDisabled ? theme.widget?.Form?.membershipDiscount?.checkboxLabel?.disabled : {}),
}));

export const FormGroup = styled.div`
    all: revert;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
`;

export interface IFormStyleOverrides extends Pick<ICSSProperties, "margin" | "padding" | "gap"> {
    headingLevel?: IHeadingProps["level"];
    customerScenarioControls?: Pick<IGridProps, "gap"> & {
        fieldsetVariant?: IFieldsetProps["variant"];
        legendVariant?: IFieldsetProps["legendVariant"];
    };
    inputFieldDisclaimer?: Pick<ICSSProperties, "margin" | "fontFamily" | "fontWeight" | "fontSize" | "lineHeight" | "color">;
    mortgageAmountDisclaimer?: Pick<ICSSProperties, "margin">;
    downPaymentDisclaimer?: Pick<ICSSProperties, "margin">;
    listInterestDisclaimer?: Pick<ICSSProperties, "margin">;
    listInterestSliderWithField?: Pick<ICSSProperties, "margin">;
    membershipDiscount?: {
        description?: Pick<ICSSProperties, "fontFamily" | "fontSize" | "fontWeight" | "lineHeight" | "margin" | "color">;
        heading?: Pick<IHeadingProps, "level"> & Pick<ICSSProperties, "fontFamily" | "fontSize" | "fontWeight" | "lineHeight" | "margin" | "color">;
        checkboxLabel?: Pick<ICSSProperties, "fontFamily" | "fontSize" | "fontWeight" | "lineHeight" | "gap" | "color" | "cursor"> & {
            disabled?: Pick<ICSSProperties, "color" | "opacity" | "cursor">;
        };
    };
    formEndDisclaimer?: {
        label?: Pick<IHeadingProps, "level"> & Pick<ICSSProperties, "margin">;
        disclaimer?: Pick<ICSSProperties, "margin" | "padding" | "fontFamily" | "fontWeight" | "fontSize" | "lineHeight" | "color">;
    };
}

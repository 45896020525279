/* eslint-disable import/no-import-module-exports */
import { generateMountFunctions } from "@Clients/client.helpers";
import { getConfiguration } from "./config";
import { theme } from "./theme";
import packageJson from "../../../package.json";

const { mount, rerender } = generateMountFunctions(getConfiguration, theme);
// ESM export
export const EconansMonthlyCost = {
    mount,
    version: packageJson.version,
};

// Window export
export default EconansMonthlyCost;

// Make hot module reloading work for config and theme files
if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept(["./config", "./theme"], () => rerender(getConfiguration, theme));
}

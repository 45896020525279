import { css } from "@emotion/react";
import type { Theme } from "@emotion/react";
import type { BreakpointEnum, ICSSProperties } from "@econans/ui-components";
import type { ResultTableKeys } from "./ResultTable.types";

const resultTable = (theme: Theme) => `
    ${
        css({
            gridTemplateColumns: `1fr auto minmax(${theme.widget?.ResultTable?.expenseMinWidth ?? "115px"}, max-content)`,
            gap: theme.widget?.ResultTable?.gap,
            margin: theme.widget?.ResultTable?.margin,
        }).styles
    }

    ${theme.mq({
        theme,
        min: "DESKTOP",
        css: css(theme.widget?.ResultTable?.desktop).styles,
    })}
`;

const resultTableTooltip = (theme: Theme) => css({ margin: theme.widget?.ResultTable?.tooltips?.margin });

export const styles = { resultTable, resultTableTooltip };

interface ICommonItemStyles extends Pick<ICSSProperties, "padding" | "fontFamily" | "fontWeight" | "fontSize" | "lineHeight" | "color"> {}

export interface IResultTableStyleOverrides extends Pick<ICSSProperties, "gap" | "margin"> {
    items?: Array<ResultTableKeys>;
    expenseMinWidth?: ICSSProperties["minWidth"];
    tooltips?: Pick<ICSSProperties, "margin"> & {
        show?: boolean;
    };
    styles?: Record<
        ResultTableKeys,
        {
            term?: ICommonItemStyles;
            rate?: ICommonItemStyles;
            expense?: ICommonItemStyles;
        }
    >;
    [BreakpointEnum.DESKTOP]?: Pick<ICSSProperties, "gap" | "margin">;
}

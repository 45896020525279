export enum CustomerScenario {
    MOVE_EXISTING_MORTGAGE = "move-existing-mortgage",
    BUY_NEW_HOUSING = "buy-new-housing",
}

export interface IMembershipDiscountValues {
    partner: string;
    discount: number;
}

export interface IFormValues {
    customerScenario: CustomerScenario;
    housingValue: number;
    mortgageAmount: number;
    downPayment: number;
    interestRate: number;
    listInterestId: string;
    membership: boolean;
}

export interface IResult {
    rates: {
        ltv: {
            presentationalValue: string;
            rawValue: number;
        };
        listInterest: {
            presentationalValue: string;
            rawValue: number;
        };
        listInterestPeriod?: string;
        ltvDiscount: {
            presentationalValue: string;
            rawValue: number;
        };
        membershipDiscount: {
            presentationalValue: string;
            rawValue: number;
        };
        nominalInterest: {
            presentationalValue: string;
            rawValue: number;
        };
        effectiveInterest: {
            presentationalValue: string;
            rawValue: number;
        };
        amortization: {
            presentationalValue: string;
            rawValue: number;
        };
    };
    expenses: {
        listInterest: {
            presentationalValue: string;
            rawValue: number;
        };
        ltvSavings: {
            presentationalValue: string;
            rawValue: number;
        };
        membershipSavings: {
            presentationalValue: string;
            rawValue: number;
        };
        nominalInterest: {
            presentationalValue: string;
            rawValue: number;
        };
        withDeduction: {
            presentationalValue: string;
            rawValue: number;
        };
        withoutDeduction: {
            presentationalValue: string;
            rawValue: number;
        };
        amortization: {
            presentationalValue: string;
            rawValue: number;
        };
    };
}

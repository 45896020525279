import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { BreakpointEnum, ICSSProperties } from "@econans/ui-components";

export const ContentWrapper = styled.div`
    all: revert;
    box-sizing: border-box;

    ${({ theme }) => `
        display: grid;
        gap: ${theme.widget?.App?.wrapper?.gap ?? theme.spacing(5)};
        margin: 0;
        padding: 0;
        background: ${theme.widget?.App?.wrapper?.background ?? "#FFFFFF"};
        
        ${theme.mq({
            theme,
            min: "DESKTOP",
            css: `
                grid-template-columns: 1fr 1fr;
                align-items: start;
                ${theme.widget?.App?.wrapper?.desktop?.gap != null ? `gap: ${theme.widget.App.wrapper.desktop.gap};` : ""};
                ${theme.widget?.App?.wrapper?.desktop?.background ? `background: ${theme.widget.App.wrapper.desktop.background};` : ""}
            `,
        })}
    `}
`;

export const ContentBlock = styled.section`
    all: revert;
    box-sizing: border-box;
    display: block;

    ${({ theme }) => `
        margin: 0;
        padding: ${theme.widget?.App?.blocks?.padding ?? theme.spacing(4, 2, 6)};
        ${theme.widget?.App?.blocks?.background ? `background: ${theme.widget.App.blocks.background};` : ""}
        border: ${theme.widget?.App?.blocks?.border || "none"};
        
        ${theme.mq({
            theme,
            min: "DESKTOP",
            css: css(theme.widget?.App?.blocks?.desktop).styles,
        })}
    `}
`;

export interface IAppStyleOverrides {
    wrapper?: Pick<ICSSProperties, "gap" | "background"> & {
        [BreakpointEnum.DESKTOP]?: Pick<ICSSProperties, "gap" | "background">;
    };
    blocks?: Pick<ICSSProperties, "padding" | "background" | "border"> & {
        [BreakpointEnum.DESKTOP]?: Pick<ICSSProperties, "padding" | "background" | "border">;
    };
}

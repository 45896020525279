import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/react";
import type { IOptionProps } from "@econans/ui-components";
import { MonthlyCostCalc, type MonthlyCostModels } from "@econans/calculations";

import { IClientConfiguration } from "@Clients/client.types";
import { Comparison, Form, Result } from "@Components";
import { CustomerScenario } from "@Components/shared.types";
import type { IFormValues, IResult } from "@Components/shared.types";
import { useEvent, TranslationNamespaceEnum, useConfiguration, formatLocalPercent, formatLocalAmount } from "@Utils";
import { ContentBlock, ContentWrapper } from "./App.styles";

function calculateListInterestWithDiscounts(formValues: IFormValues, configuration: IClientConfiguration): MonthlyCostModels.IBestInterestData {
    let mortgageForLtvDiscount =
        formValues.customerScenario === CustomerScenario.MOVE_EXISTING_MORTGAGE
            ? formValues.mortgageAmount
            : formValues.housingValue - formValues.downPayment;

    if (mortgageForLtvDiscount < configuration.minMortgageLtvDiscountLimit) {
        mortgageForLtvDiscount = formValues.housingValue; // If mortgage amount below limit for mortgage, we use ltv = 1
    }

    const membershipDiscount = formValues.membership ? configuration.membershipDiscount?.discount : 0;

    const newInterestData = MonthlyCostCalc.getBestInterestDataByFixationPeriodId(
        configuration.interestRates,
        formValues.housingValue,
        mortgageForLtvDiscount,
        0,
        formValues.listInterestId,
        membershipDiscount,
    );

    return newInterestData;
}

export const App = () => {
    const configuration = useConfiguration();
    const { t } = useTranslation([TranslationNamespaceEnum.FORM]);
    const { trackEvent } = useEvent(configuration);
    const localizationParameters = MonthlyCostCalc.getDefaultLocalizationParameters();
    const [formValues, setFormValues] = useState<IFormValues>({
        customerScenario: configuration.defaults.customerScenario,
        housingValue: configuration.defaults.housingValue,
        mortgageAmount: configuration.defaults.mortgageAmount,
        downPayment: configuration.defaults.housingValue - configuration.defaults.mortgageAmount,
        interestRate: configuration.defaults.interestRate,
        listInterestId: configuration.defaults.listInterestId,
        membership: false,
    });

    const displayComparison = configuration.showInterestComparison && formValues.customerScenario === CustomerScenario.MOVE_EXISTING_MORTGAGE;

    const handleChange = (property: keyof IFormValues, newValue: number | string | boolean | null) => {
        if (newValue === null) {
            return;
        }

        setFormValues((previousValues) => {
            const newValues = { ...previousValues, [property]: newValue };

            if (property === "customerScenario") {
                const maxLTV = configuration.maxLoanToValueRatio;

                if (newValue === CustomerScenario.MOVE_EXISTING_MORTGAGE) {
                    const maxMortgageAmount = Math.floor(previousValues.housingValue * maxLTV);

                    if (previousValues.mortgageAmount > maxMortgageAmount) {
                        newValues.mortgageAmount = maxMortgageAmount;
                    }
                }

                if (newValue === CustomerScenario.BUY_NEW_HOUSING) {
                    const minDownPayment = Math.ceil(previousValues.housingValue * (1 - maxLTV));

                    if (previousValues.downPayment < minDownPayment) {
                        newValues.downPayment = minDownPayment;
                    }

                    if (previousValues.downPayment > previousValues.housingValue) {
                        newValues.downPayment = previousValues.housingValue;
                    }
                }

                if (configuration.showNominalInterestInFormField && newValues.listInterestId !== "custom") {
                    const newInterestData = calculateListInterestWithDiscounts(newValues, configuration);

                    if (newInterestData.listInterest >= 0) {
                        newValues.interestRate = newInterestData.interestWithDiscount;
                    }
                }
            }

            if (property === "housingValue") {
                const maxLTV = configuration.maxLoanToValueRatio;
                const maxMortgageAmount = Math.floor(Number(newValue) * maxLTV);

                if (previousValues.customerScenario === CustomerScenario.MOVE_EXISTING_MORTGAGE) {
                    if (previousValues.mortgageAmount > maxMortgageAmount) {
                        newValues.mortgageAmount = maxMortgageAmount;
                    }
                }

                if (previousValues.customerScenario === CustomerScenario.BUY_NEW_HOUSING) {
                    if (previousValues.downPayment < Number(newValue) - maxMortgageAmount) {
                        newValues.downPayment = Number(newValue) - maxMortgageAmount;
                    }

                    if (previousValues.downPayment > Number(newValue)) {
                        newValues.downPayment = Number(newValue);
                    }
                }

                if (configuration.showNominalInterestInFormField && newValues.listInterestId !== "custom") {
                    const newInterestData = calculateListInterestWithDiscounts(newValues, configuration);

                    if (newInterestData.listInterest >= 0) {
                        newValues.interestRate = newInterestData.interestWithDiscount;
                    }
                }
            }

            if (property === "mortgageAmount") {
                const maxMortgageAmount = Math.floor(previousValues.housingValue * configuration.maxLoanToValueRatio);

                if (Number(newValue) > maxMortgageAmount) {
                    newValues.mortgageAmount = maxMortgageAmount;
                }

                if (configuration.showNominalInterestInFormField && newValues.listInterestId !== "custom") {
                    const newInterestData = calculateListInterestWithDiscounts(newValues, configuration);

                    if (newInterestData.listInterest >= 0) {
                        newValues.interestRate = newInterestData.interestWithDiscount;
                    }
                }
            }

            if (property === "downPayment") {
                const minDownPayment = Math.ceil(previousValues.housingValue * (1 - configuration.maxLoanToValueRatio));

                if (Number(newValue) <= minDownPayment) {
                    newValues.downPayment = minDownPayment;
                }
                if (Number(newValue) > previousValues.housingValue) {
                    newValues.downPayment = previousValues.housingValue;
                }

                if (configuration.showNominalInterestInFormField && newValues.listInterestId !== "custom") {
                    const newInterestData = calculateListInterestWithDiscounts(newValues, configuration);

                    if (newInterestData.listInterest >= 0) {
                        newValues.interestRate = newInterestData.interestWithDiscount;
                    }
                }
            }

            if (property === "listInterestId") {
                const newInterestData = calculateListInterestWithDiscounts(newValues, configuration);
                const newInterestRate = configuration.showNominalInterestInFormField
                    ? newInterestData.interestWithDiscount
                    : newInterestData.listInterest;

                if (newInterestData.listInterest >= 0) {
                    newValues.interestRate = newInterestRate;
                }
            }

            if (property === "interestRate") {
                newValues.listInterestId = "custom";
                newValues.membership = false;
            }

            if (property === "membership") {
                if (configuration.showNominalInterestInFormField && newValues.listInterestId !== "custom") {
                    const newInterestData = calculateListInterestWithDiscounts(newValues, configuration);

                    if (newInterestData.listInterest >= 0) {
                        newValues.interestRate = newInterestData.interestWithDiscount;
                    }
                }
            }

            return newValues;
        });

        trackEvent({
            category: "user",
            action: "input",
            label: `Changed ${property}`,
            data: newValue,
        });
    };

    const interestOptions: Array<IOptionProps> = [
        { value: "custom", label: t("list-interest-dropdown-label"), disabled: true },
        ...configuration.interestRates.map(({ id, label }) => ({
            value: id,
            label,
        })),
    ];

    const result: IResult = useMemo(() => {
        const mortgageAmount =
            formValues.customerScenario === CustomerScenario.MOVE_EXISTING_MORTGAGE
                ? formValues.mortgageAmount
                : formValues.housingValue - formValues.downPayment;
        const ltv = MonthlyCostCalc.calculateLoanToValueRatio(formValues.housingValue, mortgageAmount) || 0;

        const membershipDiscount = formValues.membership ? configuration.membershipDiscount?.discount : 0;

        // Set LTV to 100% (mortgage = housing value) if below min mortgage for discount
        const mortgageForLtvDiscount = mortgageAmount < configuration.minMortgageLtvDiscountLimit ? formValues.housingValue : mortgageAmount;
        const bestListInterestRates = MonthlyCostCalc.getBestInterestDataByFixationPeriodId(
            configuration.interestRates,
            formValues.housingValue,
            mortgageForLtvDiscount,
            formValues.interestRate,
            formValues.listInterestId,
            membershipDiscount,
        );
        const listInterestPeriod = configuration.interestRates.find((listInterestData) => listInterestData.id === formValues.listInterestId)?.period;
        const calculationsInput: MonthlyCostModels.IMonthlyCostInput = {
            mortgageAmount,
            housingValue: formValues.housingValue,
            interestData: bestListInterestRates,
            userInterestRate: formValues.interestRate,
        };
        const { amortization, interestExpenses, totalExpenseWithDeduction, totalExpenseWithoutDeduction } = MonthlyCostCalc.calculateMonthlyExpenses(
            calculationsInput,
            1,
            localizationParameters,
        );

        return {
            rates: {
                ltv: {
                    presentationalValue: `${formatLocalPercent(ltv, 0)}${t(`${TranslationNamespaceEnum.COMMON}:percent`)}`,
                    rawValue: ltv,
                },
                listInterest: {
                    presentationalValue:
                        bestListInterestRates.listInterest < 0
                            ? `-${t(`${TranslationNamespaceEnum.COMMON}:percent`)}`
                            : `${formatLocalPercent(bestListInterestRates.listInterest)}${t(`${TranslationNamespaceEnum.COMMON}:percent`)}`,
                    rawValue: bestListInterestRates.listInterest,
                },
                listInterestPeriod,
                ltvDiscount: {
                    presentationalValue: `${formatLocalPercent(bestListInterestRates.loanToValueDiscount)}${t(
                        `${TranslationNamespaceEnum.COMMON}:percent`,
                    )}`,
                    rawValue: bestListInterestRates.loanToValueDiscount,
                },
                membershipDiscount: {
                    presentationalValue: `${formatLocalPercent(bestListInterestRates.membershipDiscount)}${t(
                        `${TranslationNamespaceEnum.COMMON}:percent`,
                    )}`,
                    rawValue: bestListInterestRates.membershipDiscount,
                },
                nominalInterest: {
                    presentationalValue: `${formatLocalPercent(bestListInterestRates.interestWithDiscount)}${t(
                        `${TranslationNamespaceEnum.COMMON}:percent`,
                    )}`,
                    rawValue: bestListInterestRates.interestWithDiscount,
                },
                effectiveInterest: {
                    presentationalValue: `${formatLocalPercent(bestListInterestRates.effectiveInterest)}${t(
                        `${TranslationNamespaceEnum.COMMON}:percent`,
                    )}`,
                    rawValue: bestListInterestRates.effectiveInterest,
                },
                amortization: {
                    presentationalValue: `${formatLocalPercent(amortization.rate)}${t(`${TranslationNamespaceEnum.COMMON}:percent`)}`,
                    rawValue: amortization.rate,
                },
            },
            expenses: {
                listInterest: {
                    presentationalValue: `${interestExpenses.listInterest < 0 ? "-" : formatLocalAmount(interestExpenses.listInterest)}${t(
                        `${TranslationNamespaceEnum.CURRENCY}:sek-per-month`,
                    )}`,
                    rawValue: interestExpenses.listInterest,
                },
                nominalInterest: {
                    presentationalValue: `${formatLocalAmount(interestExpenses.interest)}${t(`${TranslationNamespaceEnum.CURRENCY}:sek-per-month`)}`,
                    rawValue: interestExpenses.interest,
                },
                amortization: {
                    presentationalValue: `${formatLocalAmount(amortization.amount)}${t(`${TranslationNamespaceEnum.CURRENCY}:sek-per-month`)}`,
                    rawValue: amortization.amount,
                },
                withDeduction: {
                    presentationalValue: `${formatLocalAmount(totalExpenseWithDeduction)}${t(`${TranslationNamespaceEnum.CURRENCY}:sek-per-month`)}`,
                    rawValue: totalExpenseWithDeduction,
                },
                withoutDeduction: {
                    presentationalValue: `${formatLocalAmount(totalExpenseWithoutDeduction)}${t(
                        `${TranslationNamespaceEnum.CURRENCY}:sek-per-month`,
                    )}`,
                    rawValue: totalExpenseWithoutDeduction,
                },
                ltvSavings: {
                    presentationalValue: `${formatLocalAmount(interestExpenses.loanToValueDiscountSavings)}${t(
                        `${TranslationNamespaceEnum.CURRENCY}:sek-per-month`,
                    )}`,
                    rawValue: interestExpenses.loanToValueDiscountSavings,
                },
                membershipSavings: {
                    presentationalValue: `${formatLocalAmount(interestExpenses.membershipDiscountSavings)}${t(
                        `${TranslationNamespaceEnum.CURRENCY}:sek-per-month`,
                    )}`,
                    rawValue: interestExpenses.membershipDiscountSavings,
                },
            },
        };
    }, [
        configuration.interestRates,
        configuration.minMortgageLtvDiscountLimit,
        configuration.membershipDiscount,
        formValues.customerScenario,
        formValues.downPayment,
        formValues.housingValue,
        formValues.interestRate,
        formValues.listInterestId,
        formValues.mortgageAmount,
        formValues.membership,
        localizationParameters,
        t,
    ]);

    return (
        <ContentWrapper>
            <ContentBlock>
                <Form formValues={formValues} interestOptions={interestOptions} handleChange={handleChange} />
            </ContentBlock>
            <ContentBlock>
                {result && (
                    <React.Fragment>
                        <Result
                            customerScenario={formValues.customerScenario}
                            result={result}
                            css={(theme) => css`${displayComparison ? `margin-bottom: ${theme.spacing(10)};` : ""};`}
                        />
                        {displayComparison && (
                            <Comparison
                                rates={{
                                    effectiveInterest: result.rates.effectiveInterest,
                                    nominalInterest: result.rates.nominalInterest,
                                    ltvDiscount: result.rates.ltvDiscount,
                                    membershipDiscount: result.rates.membershipDiscount,
                                    listInterestPeriod: result.rates.listInterestPeriod,
                                }}
                                mortgageAmount={formValues.mortgageAmount}
                            />
                        )}
                    </React.Fragment>
                )}
            </ContentBlock>
        </ContentWrapper>
    );
};

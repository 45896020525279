import React, { useCallback, useMemo, useRef } from "react";
import { css, useTheme } from "@emotion/react";
import { Trans, useTranslation } from "react-i18next";
import {
    DescriptionDetail,
    DescriptionList,
    DescriptionTerm,
    Heading,
    NonBreakingSpace,
    Paragraph,
    TooltipModal,
    TransLink,
} from "@econans/ui-components";

import type { IResult } from "@Components/shared.types";
import { TranslationNamespaceEnum, useConfiguration, useEvent } from "@Utils";
import { styles } from "./ResultTable.styles";
import type { ResultItemValues, ResultTableItems } from "./ResultTable.types";

export interface IResultTableProps {
    result: IResult;
}

export const ResultTable = ({ result }: IResultTableProps) => {
    const theme = useTheme();
    const { t } = useTranslation([TranslationNamespaceEnum.RESULT]);
    const configuration = useConfiguration();
    const { trackEvent } = useEvent(configuration);
    const tableRef = useRef(null);
    const { expenses, rates } = result;

    const handleTooltipToggle = useCallback(
        (tooltip: "amortization" | "interest") => {
            trackEvent({
                category: "user",
                action: "click",
                label: `Clicked info ${tooltip}`,
            });
        },
        [trackEvent],
    );

    const resultTableItems: ResultTableItems = useMemo(
        () => ({
            "list-interest": {
                term: t("list-interest"),
                rate: rates.listInterest.presentationalValue,
                expense: expenses.listInterest.presentationalValue,
            },
            "ltv-discount": {
                term: t("ltv-discount"),
                rate: rates.ltvDiscount.presentationalValue,
                expense: expenses.ltvSavings.presentationalValue,
                predicate: () => !configuration.hideLtvDiscountInResultWhenZero || expenses.ltvSavings.rawValue > 0,
            },
            "nominal-interest": {
                term: t("nominal-interest", { period: rates.listInterestPeriod ? `(${rates.listInterestPeriod.slice(0, 5)})` : "" }),
                rate: rates.nominalInterest.presentationalValue,
                expense: expenses.nominalInterest.presentationalValue,
                tooltip: (
                    <TooltipModal
                        id="econans-montly-cost-interest-info"
                        boundryElementRef={tableRef}
                        onToggle={() => handleTooltipToggle("interest")}
                        css={styles.resultTableTooltip}
                    >
                        <React.Fragment>
                            <Heading level="4">{t(`${TranslationNamespaceEnum.INFO}:interest-heading`)}</Heading>
                            <Trans
                                t={t}
                                i18nKey={`${TranslationNamespaceEnum.INFO}:interest-text`}
                                tOptions={{
                                    interpolation: { escapeValue: false },
                                }}
                                components={{
                                    nobr: <NonBreakingSpace />,
                                    p: <Paragraph noBottomMarginOnLast css={css`white-space: normal;`} />,
                                    url: <TransLink />,
                                }}
                            />
                        </React.Fragment>
                    </TooltipModal>
                ),
            },
            amortization: {
                term: t("amortization"),
                rate: rates.amortization.presentationalValue,
                expense: expenses.amortization.presentationalValue,
                tooltip: (
                    <TooltipModal
                        id="econans-montly-cost-amortization-info"
                        boundryElementRef={tableRef}
                        onToggle={() => handleTooltipToggle("amortization")}
                        css={styles.resultTableTooltip}
                    >
                        <React.Fragment>
                            {/* TODO: Move the border to LH theme when Heading can support it */}
                            <Heading level="4">{t(`${TranslationNamespaceEnum.INFO}:amortization-heading`)}</Heading>
                            <Trans
                                t={t}
                                i18nKey={`${TranslationNamespaceEnum.INFO}:amortization-text`}
                                tOptions={{
                                    interpolation: { escapeValue: false },
                                }}
                                components={{
                                    nobr: <NonBreakingSpace />,
                                    p: <Paragraph noBottomMarginOnLast css={css`white-space: normal;`} />,
                                    url: <TransLink />,
                                }}
                            />
                        </React.Fragment>
                    </TooltipModal>
                ),
            },
            "effective-interest": {
                term: t("effective-interest"),
                rate: rates.effectiveInterest.presentationalValue,
            },
            "membership-discount": {
                term: t("membership-discount"),
                rate: rates.membershipDiscount.presentationalValue,
                expense: expenses.membershipSavings.presentationalValue,
                predicate: () => rates.membershipDiscount.rawValue > 0,
            },
            mortgage: {
                term: t("mortgage-rate"),
                rate: rates.ltv.presentationalValue,
            },
        }),
        [configuration, rates, expenses, handleTooltipToggle, t],
    );

    return (
        <DescriptionList css={styles.resultTable} ref={tableRef}>
            {(theme.widget?.ResultTable?.items || Object.keys(resultTableItems)).map((itemKey) => {
                const { term, rate, expense, tooltip, predicate }: ResultItemValues = resultTableItems[itemKey];

                if (typeof predicate === "function" && !predicate()) {
                    return null;
                }

                return (
                    <React.Fragment key={term}>
                        <DescriptionTerm
                            css={() => {
                                const termStyles = theme.widget?.ResultTable?.styles?.[itemKey]?.term;
                                return css({
                                    ...termStyles,
                                    fontFamily: termStyles?.fontFamily ? theme.typography.font[termStyles.fontFamily] : undefined,
                                    fontSize: termStyles?.fontSize ? theme.typography.size[termStyles.fontSize] : undefined,
                                    whiteSpace: "nowrap",
                                });
                            }}
                        >
                            {term}
                            {theme.widget?.ResultTable?.tooltips?.show && tooltip}
                        </DescriptionTerm>
                        <DescriptionDetail
                            css={() => {
                                const rateStyles = theme.widget?.ResultTable?.styles?.[itemKey]?.rate;
                                return css({
                                    ...rateStyles,
                                    fontFamily: rateStyles?.fontFamily ? theme.typography.font[rateStyles.fontFamily] : undefined,
                                    fontSize: rateStyles?.fontSize ? theme.typography.size[rateStyles.fontSize] : undefined,
                                    whiteSpace: "nowrap",
                                });
                            }}
                            data-testid={`${itemKey}-rate`}
                        >
                            {rate}
                        </DescriptionDetail>
                        <DescriptionDetail
                            css={() => {
                                const expenseStyles = theme.widget?.ResultTable?.styles?.[itemKey]?.expense;
                                return css({
                                    ...expenseStyles,
                                    fontFamily: expenseStyles?.fontFamily ? theme.typography.font[expenseStyles.fontFamily] : undefined,
                                    fontSize: expenseStyles?.fontSize ? theme.typography.size[expenseStyles.fontSize] : undefined,
                                    whiteSpace: "nowrap",
                                });
                            }}
                            data-testid={`${itemKey}-expense`}
                        >
                            {expense}
                        </DescriptionDetail>
                    </React.Fragment>
                );
            })}
        </DescriptionList>
    );
};

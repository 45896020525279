import { TranslationResources, LanguageKeyEnum, TranslationNamespaceEnum } from "./translations.types";

export const defaultTranslations: TranslationResources = {
    [LanguageKeyEnum.SV]: {
        [TranslationNamespaceEnum.FORM]: {
            heading: "Ditt bolån",
            "customer-scenario-label": "Jag vill",
            "customer-scenario-option-move-existing-mortgage": "Flytta mitt bolån",
            "customer-scenario-option-buy-new-housing": "Köpa ny bostad",
            "housing-value-label": "Bostadens värde",
            "housing-price-label": "Bostadens pris",
            "mortgage-amount-label": "Lånebelopp",
            "mortgage-amount-disclaimer": "Högsta lånebelopp är <nobr>{{maxLTVRatioPresentationalValue}} %</nobr> av bostadens värde.",
            "down-payment-label": "Kontantinsats",
            "down-payment-disclaimer": "Lägsta kontantinsats är <nobr>{{maxDownPaymentRatioPresentationalValue}} %</nobr> av bostadens pris.",
            "list-interest-label": "Välj bindningstid",
            "list-interest-dropdown-label": "Listräntor",
            "list-interest-disclaimer": `
                Räntan nedan visar våra listräntor. 
                Under "Så har vi räknat" ser du hur bolånet kan rabatteras utifrån din belåningsgrad.
            `,
            "form-membership-label": "Är du medlem hos {{partner}}?",
            "form-membership-description": "Som medlem får en förmånlig rabatt på ditt bolån",
            "form-membership-toggle": "Jag är medlem hos {{partner}}",
            "form-end-label": "Vad händer om räntan går upp?",
            "form-end-disclaimer":
                "Det kan vara bra att ha koll på vad som händer med kostnaden om räntan skulle gå upp. Se vad månadskostnaden blir.",
        },
        [TranslationNamespaceEnum.RESULT]: {
            heading: "Preliminär månadskostnad för ditt bolån",
            "mortgage-rate": "Belåningsgrad",
            "list-interest": "Listränta",
            "ltv-discount": "Rabatt",
            "membership-discount": "Medlemsrabatt",
            "nominal-interest": "Din ränta {{period}}",
            "effective-interest": "Effektiv ränta",
            amortization: "Amortering",
            "total-with-deduction": "Total bolånekostnad efter skatteavdrag",
            "total-without-deduction": "Före skatteavdrag",
            "calculation-explanation-heading": "Så har vi räknat",
            "calculation-explanation-heading-move-mortgage": "Så har vi räknat",
            "calculation-explanation-move-mortgage": `
                <p>
                    Den prognostiserade räntekostnaden för ditt lån baseras på aktuella listräntor den dag du gör beräkningen.
                    Om räntan ändras förändras räntekostnaden under lånets löptid. Observera att det besked du får endast är en indikation
                    och inte ett bindande erbjudande.
                </p>
                <p>
                    Storleken på amorteringen bestäms av belåningsgraden (lånebelopp i förhållande till bostadens värde som du anger i kalkylen).
                    Lånebelopp som är över <nobr>50 procent</nobr> upp till <nobr>70 procent</nobr> av bostadens värde amorteras
                    med <nobr>1 procent</nobr> per år. Lånebelopp över <nobr>70 procent</nobr> av bostadens värde amorteras med
                <nobr>2 procent</nobr> per år.
                </p>
                <p>
                    Månadskostnaden räknas både före och efter skatteavdrag. Vid beräkning av skatteavdrag antar kalkylen att du/ni kan
                    göra maximala avdrag, f.n. <nobr>30 procent</nobr> för betalda räntor som inte är högre än <nobr>100 000 kr</nobr> och
                <nobr>21 procent</nobr> för räntor över <nobr>100 000 kr</nobr>. Avdragsbeloppen räknas årligen.
                </p>
                <p>
                    Effektiv ränta beräknas
                    i exemplet enligt förordning (2016:1034) om standardiserat EU-faktablad och beräkning av effektiv ränta vid bostadskrediter.
                </p>`,
            "calculation-explanation-heading-new-mortgage": "Så har vi räknat",
            "calculation-explanation-new-mortgage": `
                <p>
                    Den prognostiserade räntekostnaden för ditt lån baseras på aktuella listräntor den dag du gör beräkningen.
                    Om räntan ändras förändras räntekostnaden under lånets löptid. Observera att det besked du får endast är en indikation
                    och inte ett bindande erbjudande.
                </p>
                <p>
                    Storleken på amorteringen bestäms av belåningsgraden (lånebelopp i förhållande till bostadens värde som du anger i kalkylen).
                    Lånebelopp som är över <nobr>50 procent</nobr> upp till <nobr>70 procent</nobr> av bostadens värde amorteras
                    med <nobr>1 procent</nobr> per år. Lånebelopp över <nobr>70 procent</nobr> av bostadens värde amorteras med
                <nobr>2 procent</nobr> per år.
                </p>
                <p>
                    Månadskostnaden räknas både före och efter skatteavdrag. Vid beräkning av skatteavdrag antar kalkylen att du/ni kan
                    göra maximala avdrag, f.n. <nobr>30 procent</nobr> för betalda räntor som inte är högre än <nobr>100 000 kr</nobr> och
                <nobr>21 procent</nobr> för räntor över <nobr>100 000 kr</nobr>. Avdragsbeloppen räknas årligen.
                </p>
                <p>
                    Effektiv ränta beräknas
                    i exemplet enligt förordning (2016:1034) om standardiserat EU-faktablad och beräkning av effektiv ränta vid bostadskrediter.
                </p>`,
        },
        [TranslationNamespaceEnum.INFO]: {
            "interest-heading": "Din ränta",
            "interest-text": `
                <p>Räntan inkluderar rabatt baserat på belåningsgrad.</p>
            `,
            "amortization-heading": "Amortering",
            "amortization-text": `
                <p>Hur mycket hushållet behöver amortera på bolånet beror på vilket amorteringsregelverk lånet har, belåningsgraden och 
                hushållets totala inkomst.</p>
                <p>Bolån med en belåningsgrad om <nobr>50-70 %</nobr> ska amorteras med minst <nobr>1 %</nobr> per år.</p>
                <p>Bolån med en belåningsgrad över <nobr>70 %</nobr> av bostadens värde amorteras med <nobr>2 %</nobr> per år.</p>
                <p>Utöver detta behöver hushållet amortera ytterligare <nobr>1 %</nobr> per år om bolånen är mer än <nobr>4,5 gånger</nobr> 
                hushållets totala bruttoårsinkomst (skuldkvot). Det gäller även om belåningsgraden är under <nobr>50 procent</nobr> av bostadens 
                värde.</p>
            `,
        },
        [TranslationNamespaceEnum.CTA]: {
            "move-mortgage-label": "Ansök om att flytta ditt bolån",
            "new-mortgage-label": "Ansök om nytt bolån",
        },
        [TranslationNamespaceEnum.COMPARISON]: {
            heading: "Jämför räntan",
            "user-interest-label": "Vilken är din nuvarande ränta?",
            "savings-label": "Så mycket kan du spara genom att flytta ditt bolån:",
            "list-interest-period": "bundet på {{listInterestPeriod}} ",
            "negative-difference": "Grattis, du verkar redan ha en bra ränta!",
            disclaimer: `
                Summan gäller efter skattereduktion. Räntesatserna baseras på att du har rätt till ränterabatt 
                om <nobr>{{discount}} procentenheter</nobr>. Prognosen är inte ett erbjudande. 
                Räntan är beräknad med {{nominalInterest}} {{listInterestPeriod}} 
                (effektiv ränta {{effectiveInterest}}).
            `,
        },
        [TranslationNamespaceEnum.CURRENCY]: {
            sek: "kr",
            "sek-per-month": " kr / mån",
        },
        [TranslationNamespaceEnum.COMMON]: {
            percent: " %",
        },
    },
};

const MONTHS_IN_YEAR = 12;

export const toDecimal = (amount: number) => amount / 100;

export const toPercent = (amount: number) => amount * 100;

export function formatLocalAmount(value: number, minDecimals = 0, maxDecimals = 0, locale = "sv-SE", options?: Intl.NumberFormatOptions): string {
    const formatter = new Intl.NumberFormat(locale, {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals,
        ...(options || {}),
    });

    return formatter.format(value);
}

export function yearToMonth(amount: number) {
    return amount / MONTHS_IN_YEAR;
}

export function formatLocalPercent(value: number, decimals = 2) {
    return toPercent(value).toFixed(decimals).replace(".", ",");
}

export function removeWhiteSpaceFromString(string: string) {
    return string.replace(/\s/g, "");
}

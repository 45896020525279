import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { Heading, Label, NonBreakingSpace, Paragraph, SliderWithField } from "@econans/ui-components";

import { formatLocalAmount, formatLocalPercent, toDecimal, TranslationNamespaceEnum, useConfiguration, useEvent } from "@Utils";
import type { IFormValues, IResult } from "@Components/shared.types";
import { styles } from "./Comparison.styles";

interface IComparisonProps extends React.HTMLAttributes<HTMLDivElement>, Pick<IFormValues, "mortgageAmount"> {
    rates: Pick<IResult["rates"], "nominalInterest" | "effectiveInterest" | "ltvDiscount" | "membershipDiscount" | "listInterestPeriod">;
}

export const Comparison: React.FC<IComparisonProps> = ({ rates, mortgageAmount, ...rest }) => {
    const theme = useTheme();
    const { t } = useTranslation([TranslationNamespaceEnum.COMPARISON]);
    const configuration = useConfiguration();
    const { trackEvent } = useEvent(configuration);

    const [userInterestRate, setUserInterestRate] = useState(configuration.defaults.userInterestRate);
    const { nominalInterest, effectiveInterest, listInterestPeriod, ltvDiscount, membershipDiscount } = rates;

    const handleInterestChange = (newValue: number, sliderIsReleased: boolean) => {
        const decimalValue = toDecimal(newValue);
        setUserInterestRate(decimalValue);

        if (sliderIsReleased) {
            trackEvent({
                category: "user",
                action: "input",
                label: "compare interest",
                data: decimalValue,
            });
        }
    };

    const userInterestRateDiff = userInterestRate - nominalInterest.rawValue;
    const housingExpenseSavingAmount = Math.round((mortgageAmount * userInterestRateDiff) / 12);

    const housingExpenseSavings = `${housingExpenseSavingAmount ? formatLocalAmount(housingExpenseSavingAmount) : "-"}${t(
        `${TranslationNamespaceEnum.CURRENCY}:sek-per-month`,
    )}`;

    const rawDiscount = (ltvDiscount.rawValue || 0) + (membershipDiscount.rawValue || 0);
    const discountRate = rawDiscount ? formatLocalPercent((ltvDiscount.rawValue || 0) + (membershipDiscount.rawValue || 0)) : "0";

    return (
        <article css={styles.comparison} {...rest}>
            <Heading level={theme.widget?.Comparison?.headingLevel || "3"}>{t("heading")}</Heading>

            <form onSubmit={(event) => event.preventDefault()} css={styles.comparisonForm}>
                <Label htmlFor="user-interest-rate">{t("user-interest-label")}</Label>
                <SliderWithField
                    fieldType="decimal"
                    value={userInterestRate * 100}
                    onChange={handleInterestChange}
                    min={0}
                    max={10}
                    step={0.05}
                    suffix="%"
                    fieldHtmlAttributes={{
                        id: "user-interest-rate",
                        maxLength: 6,
                    }}
                    sliderHtmlAttributes={{
                        "aria-labelledby": "user-interest-rate",
                    }}
                />
            </form>

            {housingExpenseSavingAmount >= 0 ? (
                <React.Fragment>
                    <Heading level={theme.widget?.Comparison?.result?.headingLevel || "5"}>{t("savings-label")}</Heading>
                    <strong data-testid="comparisonSavings" css={styles.comparisonResultValue}>
                        {housingExpenseSavings}
                    </strong>
                </React.Fragment>
            ) : (
                <Paragraph data-testid="comparisonSavingsPlaceholder" css={styles.comparisonResultNegativeDifferenceDisclaimer}>
                    {t(`negative-difference`)}
                </Paragraph>
            )}

            <Paragraph data-testid="comparisonDisclaimer" css={styles.comparisonDisclaimer}>
                <Trans
                    t={t}
                    i18nKey="disclaimer"
                    tOptions={{
                        interpolation: { escapeValue: false },
                    }}
                    components={{ nobr: <NonBreakingSpace /> }}
                    values={{
                        discount: `${discountRate}`,
                        nominalInterest: `<nobr>${nominalInterest.presentationalValue}</nobr>`,
                        listInterestPeriod: listInterestPeriod ? t("list-interest-period", { listInterestPeriod }) : "",
                        effectiveInterest: `<nobr>${effectiveInterest.presentationalValue}</nobr>`,
                    }}
                />
            </Paragraph>
        </article>
    );
};

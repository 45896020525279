import { createTheme } from "@econans/ui-components";

const palette = {
    neutral: {
        10: "#fff",
        20: "#eee",
        30: "#ccc",
        40: "#999",
        50: "#666",
        60: "#333",
        70: "#222",
        80: "#111",
        90: "#000",
    },
};

export const theme = createTheme({
    baseSpacing: { amount: 4, unit: "px" },
    overrides: {
        widget: {
            Result: {
                displayExplanationAccordion: true,
            },
        },
        breakpoint: {
            matcher: "viewportWidth",
            desktop: 769,
        },
        component: {
            Button: {
                variant: {
                    primary: {
                        background: palette.neutral[20],
                        color: palette.neutral[40],
                    },
                },
            },
            Slider: {
                thumb: {
                    width: "24px",
                    height: "24px",
                    background: "#505050",
                    borderRadius: "24px",
                },
                track: {
                    height: "4px",
                    backgroundColor: "#505050",
                    backgroundColorRightOfThumb: "#B8B2A9",
                },
            },
        },
        formInput: {
            background: palette.neutral[10],
            border: `1px solid ${palette.neutral[30]}`,
            borderRadius: 0,
        },
        typography: {
            font: {
                primary: "Arial, Helvetica, sans-serif",
                secondary: "Arial, Helvetica, sans-serif",
            },
            size: {
                xs: "10px",
                sm: "12px",
                md: "16px",
                lg: "24px",
                xl: "32px",
            },
            color: {
                primary: "#000",
            },
        },
    },
});
